const closeModal = () => {
	const html = document.querySelector('html'),
		body = document.getElementsByTagName('body')[0],
		targetModal = document.querySelector('.site-modal'),
		targetModalContainer = document.querySelector('.site-modal-container');

	targetModal.addEventListener('click', function(event) {
		if (this === event.target) {
			html.style.overflowX = 'hidden';
			body.classList.remove('compensate-for-scrollbar');
			this.classList.remove('fade-in');
		}
		if (targetModalContainer === event.target) {
			html.style.overflowX = 'hidden';
			body.classList.remove('compensate-for-scrollbar');
			targetModalContainer.parentNode.classList.remove('fade-in');
		}
	});
};

export default closeModal;
